/* eslint-disable @typescript-eslint/no-explicit-any */
import { defineNuxtPlugin } from '#app';
import Cleave from 'cleave.js';

interface CleaveElement extends HTMLElement {
    cleave?: Cleave;
    value?: string;
}

export default defineNuxtPlugin((nuxtApp) => {
    nuxtApp.vueApp.directive('cleave', {
        mounted: (el, binding) => {
            const target = getInput(el);
            if (target) {
                target.cleave = new Cleave(target!, binding.value || {});
            }
        },
        updated: (el) => {
            const event = new Event('input', { bubbles: true });
            const target = getInput(el);
            if (target) {
                setTimeout(function () {
                    target.value = target.cleave?.properties.result;
                    target.dispatchEvent(event);
                }, 100);
            }
        },
    });

    return {
        provide: {
            cleave: {
                phone: {
                    numericOnly: true,
                    blocks: [3, 3, 4],
                    delimiters: ['-', '-', '-'],
                },
                currency: {
                    numeral: true,
                    numeralThousandsGroupStyle: 'thousand',
                    numeralPositiveOnly: true,
                    numeralDecimalScale: 2,
                    prefix: '$ ',
                },
                date: {
                    date: true,
                    datePattern: ['m', 'd', 'Y'],
                    delimiter: '/',
                },
            },
        },
    };
});

function getInput(el: HTMLElement): CleaveElement | undefined {
    if (el) {
        if (el.tagName === 'INPUT') {
            return el as CleaveElement;
        }
        for (let i = 0; i < el.children.length; i++) {
            if (el.children[i].tagName === 'INPUT') {
                return el.children[i] as CleaveElement;
            }
        }
    }
}
