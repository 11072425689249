/* eslint-disable @typescript-eslint/no-explicit-any */
import { defineNuxtPlugin } from '#app';
import * as yup from 'yup';
import type { ObjectSchema as YupObjectSchema, ValidationError as YupError } from 'yup';
import type { FormError } from '#ui/types'

interface LocalizedError {
    key: string;
    values?: any[];
}



export default defineNuxtPlugin(() => {
    // Validation localization
    yup.setLocale({
        // use constant translation keys for messages without values
        mixed: {
            default: "validation.invalid",
            required: "validation.required",
        },
        string: {
            email: "validation.email",
        },
        number: {
            min: ({ min }) => ({ key: "validation.min", values: [ min ] }),
            max: ({ max }) => ({ key: "validation.max", values: [ max ] }),
            positive: "validation.positive",
        },
    });

    yup.addMethod<yup.StringSchema>(yup.string, "currency", function (this: any, errorMessage: any) {
        return this.test("text-currency", errorMessage, function (this: any, value: string) {
            const { path, createError } = this;
            let isValid = false;

            if (value) {
                value = value.replace(/[^0-9.]/g, '');
                const amt = parseFloat(value.replace(/[^0-9.]/g, ''));
                if (!isNaN(amt) && amt >= 0) { isValid = true }
            }

            return (isValid || createError({ path, message: errorMessage || 'validation.currency' }));
        });
    });

    return {
        provide: {
            getLocalizedFormErrors: async (
                t: (msg: string, params?: any[]) => string,
                state: any,
                schema: YupObjectSchema<any>
              ): Promise<FormError[]> => {
                try {
                  await schema.validate(state, { abortEarly: false })
                  return []
                } catch (error) {
                  if (isYupError(error)) {
                    return error.inner.map((issue) => {
                      if(isLocalizedError(issue.message)) {
                        const msg = issue.message as unknown as LocalizedError;
                        return {
                          path: issue.path ?? '',
                          message: t(msg.key, msg.values ?? [])
                        }
                      } else {
                      return {
                          path: issue.path ?? '',
                          message: t(issue.message)
                        }
                      }
                  })
                  } else {
                    throw error
                  }
                }
            }
        }
    }
});

function isYupError (error: any): error is YupError {
    return error.inner !== undefined
}

function isLocalizedError (message: any): boolean {
    return message && message.key !== undefined
}