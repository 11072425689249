import { signOut, fetchAuthSession, getCurrentUser } from 'aws-amplify/auth';
import type { User } from '~/types';

export const useAuthStore = defineStore('authStore', {
    state: () => ({
        loading: true,
        user: {} as User,
        exp: 0,
    }),
    getters: {
        isAuthenticated(state): boolean {
            return !!state.user && Object.keys(state.user).length > 0 && !state.loading;
        },
        isSystemAdmin(state): boolean { 
            return state.user.roleId === 'ADMIN';
        },
        isCspAdmin(state): boolean {
            return state.user.roleId === 'CSP_ADMIN' || this.isSystemAdmin;
        }
    },
    actions: {
        async init() {
            this.loading = true;

            const { $api } = useNuxtApp();
            const toast = useToast();

            // Check is user is already logged in
            // If not, wait to init auth
            try {
                await getCurrentUser();
            } catch (error) {
                this.loading = false;
                return
            }

            // Get current user details from the API
            try {
                this.user = await $api<User>(`v1/users/current`);
                localStorage.setItem('isCspAdmin', JSON.stringify(this.isCspAdmin))
                localStorage.setItem('isSystemAdmin', JSON.stringify(this.isSystemAdmin))
            } catch (error) {
                toast.add({
                    title: 'Unable to log in',
                    description: 'Please contact your system administrator for assistance',
                    ...toastConstants.ERROR,
                });
                console.error(error);
                this.signOut();
            }
            this.loading = false;
        },
        async getIsSystemAdmin(){
            return localStorage.getItem('isSystemAdmin')?.toLowerCase() === 'true';
        },
        async getIsCspAdmin(){
            return localStorage.getItem('isCspAdmin')?.toLowerCase() === 'true';
        },
        async refresh(){
            return await fetchAuthSession({forceRefresh: true});
        },
        signOut() {
            this.loading = true;
            signOut();
            this.user = {} as User;
            this.loading = false;
            localStorage.setItem('isCspAdmin', JSON.stringify(this.isCspAdmin))
            localStorage.setItem('isSystemAdmin', JSON.stringify(this.isSystemAdmin))
        },
    },
});
